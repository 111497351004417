import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/config/firebaseInit.js";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const getDefaultState = () => {
  return {
    user: {},
  };
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    user: {},
  },
  mutations: {
    saveUser(state, data) {
      state.user = data;
    },
    clearUser(state) {
      state = Object.assign(state, getDefaultState())
    },
  },
  actions: {
    signOut(context) {
      context.commit("clearUser");
    },
    async getUserData(context, id) {
      let data = await db.doc(`administrator/${id}`).get();
      data = Object.assign({ id: data.id }, data.data());
      context.commit("saveUser", data);
      return data;
    },
  },
  modules: {},
});
