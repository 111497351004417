import firebase from "firebase";
import "firebase/firestore";
import getDBConfig from "./firebaseConfig";

let firebaseConfig = getDBConfig(process.env.NODE_ENV)
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
const fieldValue = firebase.firestore.FieldValue;
const auth = firebase.auth();
const functions = firebase.functions();
const batch = db.batch()
export { db, storage, fieldValue, auth, functions, batch };
