const getDBConfig = () => {
  // if (env == "prod")
    return {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_DATABASE_URL,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_APP_ID,
    };
  //STAGING
  // else
    // return {
    //   apiKey: process.env.VUE_APP_STAGING_FIREBASE_API_KEY,
    //   authDomain: process.env.VUE_APP_STAGING_AUTH_DOMAIN,
    //   databaseURL: process.env.VUE_APP_STAGING_DATABASE_URL,
    //   projectId: process.env.VUE_APP_STAGING_PROJECT_ID,
    //   storageBucket: process.env.VUE_APP_STAGING_STORAGE_BUCKET,
    //   messagingSenderId: process.env.VUE_APP_STAGING_MESSAGING_SENDER_ID,
    //   appId: process.env.VUE_APP_STAGING_APP_ID,
    // };
};

export default getDBConfig;
