<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button
        @click="
          (administratorInit = !administratorInit),
            (updateAdministratorInit = false)
        "
        style="z-index: 1;"
        class="md-primary"
        >New Admin</md-button
      >
      <md-card v-if="administratorInit">
        <md-card-content>
          <form @submit.prevent>
            <div>
              <h3>Admin Access</h3>
              <p>
                Diamond Services USA
                <input
                  v-model="selectedAdminAccess"
                  type="radio"
                  :value="true"
                  id=""
                />
              </p>
              <div
                class="admin-radio-options"
                v-if="selectedAdminAccess == true"
              >
                <div class="radio-option">
                  - <span>Q/A Employee</span>
                  <input
                    v-model="selectedAdminType"
                    type="radio"
                    value="isAdmin"
                    id=""
                  />
                </div>
                <div class="radio-option">
                  - <span>Super Admin</span>
                  <input
                    v-model="selectedAdminType"
                    type="radio"
                    value="isSuperAdmin"
                    id=""
                  />
                </div>
              </div>
              <p>
                Client
                <input
                  v-model="selectedAdminAccess"
                  type="radio"
                  value="Client"
                  id=""
                />
              </p>
              <div
                v-if="selectedAdminAccess == 'Client'"
                class="filter customer"
              >
                <label for="">Select Client</label>
                <v-select
                  :appendToBody="true"
                  :deselectFromDropdown="true"
                  :options="clients"
                  label="name"
                  :clearable="false"
                  :reduce="(t) => t.id"
                  v-model="administrator.clientID"
                >
                </v-select>
              </div>
              <p>
                Customer
                <input
                  v-model="selectedAdminAccess"
                  type="radio"
                  value="Customer"
                  id=""
                />
              </p>

              <div
                v-if="selectedAdminAccess == 'Customer'"
                class="filter customer"
              >
                <label for="">Select Customer</label>
                <v-select
                  :appendToBody="true"
                  :deselectFromDropdown="true"
                  :options="customers"
                  label="name"
                  :clearable="false"
                  :reduce="(t) => t.id"
                  v-model="administrator.customerID"
                >
                </v-select>
              </div>
            </div>
            <md-field>
              <label>Full Name</label>
              <md-input
                required
                v-model="administrator.fullname"
                type="text"
              ></md-input>
            </md-field>
            <!-- <md-field>
              <the-mask
                placeholder="Phone"
                id="md-input-00tt9ig"
                class="md-input"
                v-model="administrator.phone"
                :mask="['(###) ###-####']"
              />
            </md-field> -->

            <md-field>
              <label>Email Address</label>
              <md-input
                required
                v-model="administrator.email"
                type="text"
              ></md-input>
            </md-field>
            <md-field v-if="!updateAdministratorInit">
              <label>Password</label>
              <md-input
                required
                v-model="administrator.password"
                type="text"
              ></md-input>
            </md-field>
          </form>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button
            v-if="!updateAdministratorInit"
            class="md-primary md-round md-small"
            @click="signUp()"
            >Save</md-button
          >
          <md-button
            v-else
            class="md-raised md-primary md-round md-small"
            @click="update()"
            >Save</md-button
          >

          <md-button class="md-accent md-round md-small" @click="cancel()"
            >Cancel</md-button
          >
        </md-card-actions>
      </md-card>
      <md-card v-else>
        <md-card-content>
          <md-table
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search User"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="fullname">
                {{ item.fullname }}
              </md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Authorized">{{
                item.clientID ||
                (item.isSuperAdmin
                  ? "Super Admin"
                  : item.customerID
                  ? "Customer Portal Access"
                  : "QA Employee")
              }}</md-table-cell>

              <md-table-cell md-label="">
                <md-button
                  class="md-icon-button"
                  @click.native="handleEdit(item)"
                >
                  <md-icon :style="{ color: 'green' }">visibility</md-icon>
                </md-button>
                <md-button
                  class="md-icon-button"
                  @click.native="handleDelete(item)"
                >
                  <md-icon :style="{ color: 'red' }">delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
          <md-table-toolbar class="per-page" :style="{ marginBottom: '20px' }">
            <md-field>
              <label for="orders">Per page</label>
              <md-select v-model="pagination.perPage" name="orders">
                <md-option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                  >{{ item }}</md-option
                >
              </md-select>
            </md-field>
          </md-table-toolbar>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";

import { db, functions, fieldValue } from "@/config/firebaseInit";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      administratorInit: false,
      updateAdministratorInit: false,
      customerAccessSelected: false,
      selectedAdminAccess: "",
      selectedAdminType: "isAdmin",
      currentSort: "name",
      currentSortOrder: "asc",
      counter: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Name", "Email", "Phone", "Edit", "Delete"],
      searchQuery: "",
      propsToSearch: ["firstname", "lastname", "email"],
      tableData: [],
      copyData: [],
      customers: [],
      clients: [],
      searchedData: [],
      fuseSearch: null,
      administrator: {
        isAdmin: false,
        isSuperAdmin: false,
        fullname: "",
        phone: "",
        email: "",
        password: null,
      },
      inviteEmail: null,
      showDialog: false,
      addUser: functions.httpsCallable("addUser"),
    };
  },
  watch: {
    selectedAdminAccess(val) {
      if (val == true) {
        this.administrator[this.selectedAdminType] = true;
        this.administrator.clientID = "";
        this.administrator.customerID = "";
      } else if (val == "Customer") {
        this.administrator.isAdmin = false;
        this.administrator.clientID = "";
      } else if (val == "Client") {
        this.administrator.customerID = "";
        this.administrator.isAdmin = false;
      }
    },
    updateAdministratorInit() {
      if (!this.updateAdministratorInit) {
        this.administrator = {
          isAdmin: false,
          isSuperAdmin: false,
          fullname: "",
          phone: "",
          email: "",
          password: null,
        };
      }
    },
    searchQuery(value) {
      if (value === "") {
        this.tableData = this.copyData;
        return;
      }
      let res = [];
      let q = value.toLowerCase();
      let result = this.copyData;
      result.filter((d) => {
        let phone = d["phone"].toString();
        let name = d["fullname"].toLowerCase();
        let email = d["email"].toLowerCase();
        if (
          phone.indexOf(q) > -1 ||
          name.indexOf(q) > -1 ||
          email.indexOf(q) > -1
        ) {
          res.push(d);
          return;
        }
      });
      this.tableData = res;
    },
    selectedAdminType() {
      this.administrator[this.selectedAdminType] = true;
    },
  },

  async created() {
    await this.updateTable();
    await this.getAllCustomers();
    await this.getAllClients();
  },

  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },

  methods: {
    async updateTable() {
      await this.$bind("tableData", db.collection("administrator"));
      this.copyData = this.tableData;
    },
    async getAllCustomers() {
      const customers = await db.collection("customers").get();
      this.customers = customers.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    async getAllClients() {
      const clients = await db.collection("clients").get();
      this.clients = clients.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    handleDelete(item) {
      const admin = db.collection("administrator").doc(item.id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          await admin.delete();
          await db.doc("attributes/qa_associates").update({
            employees: fieldValue.arrayRemove(item.fullname),
          });
          await this.updateTable();
          return Swal.fire("Deleted!", "The data has been deleted.", "success");
        }
      });
    },
    goTo(id) {
      this.$router.push(`/user/${id}`);
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (
          (this.currentSort === "created") &
          (this.currentSortOrder === "desc")
        ) {
          return a[sortBy] - b[sortBy];
        }

        if (
          (this.currentSort === "created") &
          (this.currentSortOrder === "asc")
        ) {
          return b[sortBy] - a[sortBy];
        }

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleEdit(administrator) {
      this.administrator = administrator;
      this.administratorInit = true;
      this.updateAdministratorInit = true;
    },
    cancel() {
      this.administratorInit = false;
      this.updateAdministratorInit = false;
    },
    update() {
      let administratorRef = db
        .collection("administrator")
        .doc(this.administrator.id);
      let administrator = this.administrator;
      return administratorRef.set(administrator, { merge: true }).then(() => {
        this.updateAdministratorInit = false;
        this.administratorInit = false;
        this.administrator = {
          fullname: "",
          email: "",
          password: null,
        };
        setTimeout(function () {
          return Swal.fire("Success", "Administrator Updated", "success");
        }, 500);
      });
    },
    signUp() {
      let vm = this;
      if (this.validate()) {
        this.addUser({
          email: this.administrator.email,
          emailVerified: true,
          password: this.administrator.password,
          displayName: this.administrator.fullname,
          disabled: false,
        })
          .then((res) => {
            const uid = res.data;
            let administratorRef = db.collection("administrator").doc(uid);
            let administrator = this.administrator;
            administrator.uid = uid;
            delete administrator.password;
            administratorRef.set(administrator);
            return administrator.fullname;
          })
          .then((name) => {
            return db.doc("attributes/qa_associates").update({
              employees: fieldValue.arrayUnion(name),
            });
          })
          .then(async () => {
            this.administratorInit = false;
            this.administrator = {
              isAdmin: false,
              isSuperAdmin: false,
              fullname: "",
              phone: "",
              email: "",
              password: null,
            };
            Swal.fire("Success", "Administrator Created", "success");
            return this.updateTable();
          })
          .catch((err) => {
            return Swal.fire("Error", err.message, "warning");
          });
      } else {
        return Swal.fire("Error", "Missing Required Fields", "warning");
      }
    },
    validate() {
      let valid = true;
      for (let key in this.administrator) {
        if (key == "email" || key == "password" || key == "name") {
          if (!this.administrator[key]) valid = false;
        }
      }
      return valid;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.invite-modal {
  width: 500px;
}

.invite-wrapper {
  width: 75%;
  margin: 0 auto;
}
a {
  cursor: pointer;
}
.md-field {
  flex-direction: column !important;
}

.admin-radio-options {
  margin-left: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.radio-option {
  width: 150px;
}
</style>
