import Vue from "vue";
import App from "./App.vue";
import firebase from "firebase";
import router from "./router";
import store from "./store/index";
import VueMaterial from "vue-material";
import Utilities from "./utility/main"
import vSelect from "vue-select";
import { firestorePlugin } from "vuefire";
import VueLoading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';
import "./registerServiceWorker";
import "vue-select/dist/vue-select.css";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

Vue.use(VueMaterial);
Vue.use(firestorePlugin);
Vue.mixin(Utilities.mixin())
Vue.use(store)
Vue.use(VueLoading)
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;

let app = '';
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
